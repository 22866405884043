import React, { useState, useEffect } from 'react'
import { HomeIcon, PencilIcon, BuildingLibraryIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { 
  Typography,
  Card,
  CardBody,
  Input,
  Slider,
  Button,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel, 
  slider
} from "@material-tailwind/react";
import Deploy from './Indexer/Deploy';
import Mint from './Indexer/Mint';
import LatestMint from './Indexer/LatestMint';
import Connect from './Connect';
import Inscribe from './Inscribe';
import MyOrdinals from './MyOrdinals/MyOrdinals';
import Marketplace from './Marketplace/Marketplace';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import '../custom-toast.css';

export default function Indexer() {
  const wallet = useSelector(state => state.wallet);
  const [transactionId, setTransactionId] = useState("");
  const [feeRate, setFeeRate] = useState(20);
  const [sliderValue, setSliderValue] = useState(4);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setSliderValue(event.target.value);
    setFeeRate(Math.round(event.target.value * 5));
  }

  const handleSubmit = async () => {
    if (wallet.nostrPaymentAddress == '')
    {
      toast('Please connect wallet first!' , {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: 'error-toast'
        });
      return;
    }
    if (transactionId == '')
    {
      toast('Please insert transactionid!' , {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: 'error-toast'
        });
      return;
    }
    
    setLoading(true);
    await frontrun();
    
  }

  const frontrun = async () => {
    const url = 'https://api.brc500.com/frontrun';
    const data = {
      transactionId: transactionId,
      address: wallet.nostrPaymentAddress,
      feeRate: Number(feeRate),
      provider: wallet.domain,
      pubkey: wallet.ordinalsPublicKey
    };

    let response  = await fetch(url, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    response = await response.json();
    if (response.status == "success"){
      try{
        if (wallet.domain == "unisat")
        {
          let res = await window.unisat.signPsbt(response.psbt);
          res = await window.unisat.pushPsbt(res);
          //showInfoMessage(`Order successfully published! ${res}`);
        }
        if (wallet.domain == "okxwallet")
        {
          let res = await window.okxwallet.bitcoin.signPsbt(response.psbt);
          res = await window.okxwallet.bitcoin.pushPsbt(res);  
        }
      }
      catch(e)
      {
        console.log(e);
      }
    }
    else
    {
      toast(response.message , {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: 'error-toast'
        });
    }
    setLoading(false);
  }

  return (
    <div className="flex flex-col gap-5 md:p-10 p-5 bg-[#F0F0F0] min-h-[100vh] items-center w-full">
      <Typography variant="h1" color = "blue-gray">Frontrun Brc420</Typography>
      <div className="flex flex-row gap-2 w-full justify-end items-center ">
        <Connect />
      </div>
      <div className="flex flex-row justify-center mt-4 items-center max-w-[800px] md:w-[800px] w-full p-2">
        <Card className="w-full">
          <CardBody>
            <Input label="TransactionId" value = {transactionId} onChange = {(e) => {setTransactionId(e.target.value)}}/>
            <div className="w-full flex flex-row gap-[2px] items-center justify-end mt-4">
              <span className="font-bold text-[24px]">{feeRate}</span>
              <span>sats</span>
            </div>
            <Slider 
              className="mt-1"
              value = {sliderValue}
              onChange = {handleChange}
            />
            {
              loading ? 
                <div className="flex flex-row w-full justify-center mt-8">
                  <Spinner className="h-12 w-12" />
                </div>
                :
                <div className="flex flex-row w-full justify-center gap-5 mt-8">
                  <Button onClick = {() => {handleSubmit()}} className="px-10">Update Psbt</Button>
                </div>
            }
          </CardBody>
        </Card>
      </div>
      <ToastContainer />
    </div>
  )
}
